import React from "react";
import { Link } from "react-router-dom";

import "./styles/Navbar.css";
import Navbar from "react-bootstrap/Navbar";
import Nav from 'react-bootstrap/Nav'

import logo from "../images/logo_fenix.png";
/*import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
*/
class MyNavbar extends React.Component {

  state = {
    navExpanded: false
  }

  setNavExpanded = (expanded) => {
    this.setState({ navExpanded: expanded });
  }

  closeNav = () => {
    this.setState({ navExpanded: false });
  }

  render() {
    return (
      <Navbar bg='azuara' expand='lg' sticky="top" onToggle={this.setNavExpanded} expanded={this.state.navExpanded}>
        <Navbar.Brand><Link to="/" className="navbar-brand"><img alt="Logo" src={logo} height="33" /> Inflables Azuara</Link></Navbar.Brand>
        <Navbar.Toggle aria-controls='basic-navbar-nav' id='navbar-toogle-button' children="MENÚ"/>
        <Navbar.Collapse id='basic-navbar-nav' className="justify-content-end">
          <Nav className='mr-auto' onClick={this.closeNav}>
            <Link to='/juegos/1' className="nav-link">Juegos 1</Link>
            <Link to='/juegos/2' className="nav-link">Juegos 2</Link>
            <Link to='/juegos/3' className="nav-link">Juegos 3</Link>
            <Link to='/juegos/4' className="nav-link">Acuáticos</Link>
            <Link to='/juegos/5' className="nav-link">Combinados</Link>
            <Link to='/contacto' className="nav-link">Contacto</Link>            
          </Nav>
          <Navbar.Text>
            <p className="navbar-atencion">Atención de 9:00 a 18:00 hrs<br />(442)403-5798 y (442)411-0343</p>
          </Navbar.Text>
        </Navbar.Collapse>
      </Navbar>
    );
  }
}

export default MyNavbar;
