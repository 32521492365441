import React from "react";
import api from "../api";

class ContactForm extends React.Component {
  state = {
    nombre: "",
    telefono: "",
    email: "",
    text: "",
    visita: "",
    mailSent:false,
    loading: false,
    error:null
  };

  componentDidMount(){
    window.scrollTo(0,0);
  }

  handleFormSubmit = (e) => {
    e.preventDefault();
    if (!this.state.mailSent)
      this.sendData();
  };

  sendData = async () => {
    this.setState({ loading: true, error: null });

    try {
      await api.postContactForm(this.state);
      this.setState({ loading: false, mailSent: true });
    } catch (error) {
      this.setState({
        loading: false,
        error: error,
      });
    }
  };

  render() {
    return (
      <div className='container'>
        <div className='row'>
          <div className='col-lg-6'>
            <h1>Contáctenos</h1>
            <form onSubmit={this.handleFormSubmit}>
              <div className='form-group'>
                <label>Nombre</label>
                <input
                  className='form-control'
                  type='text'
                  id='nombre'
                  name='nombre'
                  placeholder='Ingrese su nombre..'
                  value={this.state.nombre}
                  onChange={(e) => this.setState({ nombre: e.target.value })}
                />
              </div>
              <div className='form-group'>
                <label>Teléfono</label>
                <input
                  className='form-control'
                  type=' text'
                  id='telefono'
                  name='telefono'
                  placeholder='Ingrese su teléfono..'
                  value={this.state.telefono}
                  onChange={(e) => this.setState({ telefono: e.target.value })}
                />
              </div>
              <div className='form-group'>
                <label>Email</label>
                <input
                  className='form-control'
                  type='email'
                  id='email'
                  name='email'
                  placeholder='Ingrese su email'
                  value={this.state.email}
                  onChange={(e) => this.setState({ email: e.target.value })}
                />
              </div>

              <div className='form-group'>
                <label>De dónde nos visita?</label>
                <select
                  name='visita'
                  id='visita'
                  className='form-control'
                  value={this.state.visita}
                  onChange={(e) => this.setState({ visita: e.target.value })}>
                  <option value=''>Seleccione una Opción</option>
                  <optgroup label='Querétaro'>
                    <option value='Queretaro'>Querétaro</option>
                    <option value='Queretaro Centro'>Querétaro Centro</option>
                    <option value='Juriquilla'>Juriquilla</option>
                    <option value='Corregidora'>Corregidora</option>
                    <option value='Marquez'>El Marquez</option>
                    <option value='SJ del Rio'>San Juan del Río</option>
                    <option value='Otro Qro'>Otro</option>
                  </optgroup>
                  <optgroup label='Cd de México'>
                    <option value='Edo Mex'>Estado de México</option>
                    <option value='DF'>D.F.</option>
                    <option value='Otro Mex'>Otro</option>
                  </optgroup>

                  <option value='Otro Estado'>Otro Estado</option>
                  <option value='Otro Pais'>Otro País</option>
                </select>
              </div>

              <div className='form-group'>
                <label>Escriba un mensaje</label>
                <textarea
                  className='form-control'
                  id='text'
                  name='text'
                  placeholder='Escriba sus dudas, comentarios o sugerencias'
                  onChange={(e) => this.setState({ text: e.target.value })}
                  value={this.state.text}
                />
              </div>
              <input
                className='btn btn-primary'
                type='submit'
                // onClick={(e) => this.handleFormSubmit(e)}
                value='Enviar'
              />
              <div>
                {this.state.mailSent && <div>Correo enviado, gracias por contactarnos.</div>}
                {this.state.error && <div>{this.state.error}</div>}
              </div>
            </form>
          </div>
          <div className="col-lg-6">
            <h1>Llámenos o envíenos un correo</h1>
            <h3>info@azuarainflables.com</h3>
            <h3>(442)411-0343</h3><h3>(442)403-5798</h3>
            <h3>Nuestro horario de atención es de Lunes a Sábado, de 9:00 a 14:00 y 16:00 a 18:00 hrs</h3>
          </div>
        </div>
      </div>
    );
  }
}

export default ContactForm;
