import React from 'react';

import './styles/ProductoList.css';
import Producto from './Producto';

class ProductosList extends React.Component {

  render() {
    if (this.props.juegos.length === 0) {
      return (
        <div>
          <h3>No hay juegos</h3>
        </div>
      );
    }

    return (
      <div className="JuegosList">
        <ul className="list-unstyled">
          {this.props.juegos.map(juego => {
            return (
              <li key={juego.id}>
               <Producto juego={juego} onGalleryClick={this.props.onGalleryClick} />
              </li>
            );
          })}
        </ul>
      </div>
    );
  }
}

export default ProductosList;
