import React, { Component } from "react";
import LazyHero from "react-lazy-hero";
import { Carousel } from "react-responsive-carousel";
import { FacebookProvider, Page, Like } from "react-facebook";
import api from "../api";
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faImages } from '@fortawesome/free-solid-svg-icons'

import "react-responsive-carousel/lib/styles/carousel.min.css";
import "./styles/Home.css";

import logo from "../images/logo_fenix.png";
import cliente from "../images/customer-service.svg";
import seguridad from "../images/shield.svg";
import limpieza from "../images/rubber-gloves.svg";
import calidad from "../images/certificate.svg";
import confetti from "../images/confetti.svg";
import reloj from "../images/hourglass.svg";
import arrow from "../images/arrow_down.png";
import { Fragment } from "react";

class Home extends Component {
  state = {
    loading: false,
    error: null,
    carrusel: undefined,
  };

  componentDidMount() {
    window.scrollTo(0,0);
    document.querySelectorAll('a[href^="#"]').forEach((anchor) => {
      anchor.addEventListener("click", function(e) {
        e.preventDefault();

        document.querySelector(this.getAttribute("href")).scrollIntoView({
          behavior: "smooth",
        });
      });
    });
    this.fetchData();
  }

  fetchData = async () => {
    this.setState({ loading: true, error: null });

    try {
      const data = await api.carrusel();
      this.setState({ loading: false, carrusel: data });
    } catch (error) {
      this.setState({
        loading: false,
        error: error,
      });
    }
  };

  render() {
    var r_carrusel = <div />;

    if (this.state.carrusel) {
      r_carrusel = (
        <div id="carousel">
          <Carousel
            showStatus={false}
            showThumbs={false}
            autoPlay={true}
            stopOnHover={false}
            infiniteLoop={true}>
            {this.state.carrusel.map((obj) => {
              return (
                <div key={obj.id}>
                  <img src={obj.url} alt={obj.titulo} />
                  <p className='legend'>{obj.titulo}</p>
                </div>
              );
            })}
          </Carousel>
          </div>
      );
    } else if (this.state.error) {
      console.log(this.state.error);
    }

    return (
      <React.Fragment>
        <LazyHero
          imageSrc='https://scontent.fgdl3-1.fna.fbcdn.net/v/t31.0-8/474982_470513862964688_1475184923_o.jpg?_nc_cat=101&_nc_ht=scontent.fgdl3-1.fna&oh=7e448774709b4469943dfa6d547d7da4&oe=5D8D4D7F'
          minHeight='95vh'
          color='#62C6FF'
          opacity={0.7}
          parallaxOffset={200}
          transitionDuration={300}>
          <img src={logo} alt='Logo' width='50%' />
          <h1>Azuara Inflables</h1>
          <h3>+ de 60 modelos diferentes</h3>
          <h3>Los mejores juegos techados de Querétaro</h3>
          <a href='#features' className="arrow">
            <img src={arrow} alt='Bajar' title='Siguiente...' />
          </a>
        </LazyHero>

        <div className='Home__section features' id='features'>
          <div className='container'>
            <h1>en Azuara Inflables nos distingue...</h1>
            <div className='row'>
              <div className='col-md-4 col-6 col-sm-6'>
                <img src={cliente} alt='Servicio al Cliente' />
                <h5>Servicio al Cliente</h5>
              </div>

              <div className='col-md-4 col-6 col-sm-6 feature'>
                <img src={seguridad} alt='Seguridad' />
                <h5>Seguridad</h5>
              </div>

              <div className='col-md-4 col-6 col-sm-6 feature'>
                <img src={limpieza} alt='Limpieza' />
                <h5>Limpieza</h5>
              </div>

              <div className='col-md-4 col-6 col-sm-6 feature'>
                <img src={calidad} alt='Calidad' />
                <h5>Calidad</h5>
              </div>

              <div className='col-md-4 col-6 col-sm-6 feature'>
                <img src={reloj} alt='Puntualidad' />
                <h5>Puntualidad</h5>
              </div>

              <div className='col-md-4 col-6 col-sm-6 feature'>
                <img src={confetti} alt='Diversión' />
                <h5>Diversión</h5>
              </div>
            </div>
            <div className="row" style={{marginTop:'100px'}}>
              <a href='#carousel' className='arrow'>
                <img src={arrow} alt='Bajar' title='Siguiente...' />
                <h1>... algunos de nuestros eventos y experiencias</h1>
              </a>
            </div>
          </div>
        </div>

        {r_carrusel}
        <div className='Home__section social'>
          <div className='container'>
          <Link to='/juegos/1' className="btn btn-lg btn-block btn-light mt-5 mb-5" style={{fontSize:'1.5em'}}><Fragment><FontAwesomeIcon icon={faImages} />&nbsp;&nbsp;&nbsp;<b>EXPLORA LA GALERÍA AQUÍ !</b>&nbsp;&nbsp;&nbsp;<FontAwesomeIcon icon={faImages} /></Fragment></Link>
            <h1>Visita nuestras redes sociales y danos Like!</h1>
            <div className='row'>
              <div className='col-12'>
                <FacebookProvider 
                appId='740282449707768'
                language='es_ES'>
                  <Page
                    href='https://www.facebook.com/AzuaraInflables/'
                    tabs='timeline'
                    width='1980px'
                    height='700px'
                    showFacepile={true}
                    adaptContainerWidth={true}
                    smallHeader={true}
                    hideCover={true}
                    hideCTA={true}
                  />
                </FacebookProvider>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}
export default Home;
