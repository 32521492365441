import React, { Fragment } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faImages } from '@fortawesome/free-solid-svg-icons'

import "./styles/Producto.css";

function formatMoney(n) {
  var c = 2,
    d = ".",
    t = ",",
    s = n < 0 ? "-" : "",
    i = String(parseInt((n = Math.abs(Number(n) || 0).toFixed(c)))),
    j = i.length > 3 ? i.length % 3 : 0;

  return (
    s +
    (j ? i.substr(0, j) + t : "") +
    i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + t) +
    (c
      ? d +
        Math.abs(n - i)
          .toFixed(c)
          .slice(2)
      : "")
  );
}

class Producto extends React.Component {

  // componentWillMount(){
  //   let that = this;
  //   this.props.juego.imagen_url !== null
  //                 ? toDataURL(this.props.juego.imagen_url, function(data) {that.setState({dataUrl:data})})
  //                 : toDataURL("https://s3.amazonaws.com/azuarainflables/images/no-disponible.png", function(data) {that.setState({dataUrl:data})})
  // }

  render() {
    var ver_mas_fotos = <div><h3>Más fotos próximamente...</h3></div>;
    if (this.props.juego.mas) {
      ver_mas_fotos = (
        <button
          onClick={this.props.onGalleryClick}
          id={this.props.juego.id}
          className='btn btn-primary Producto__btn-fotos'>
          <Fragment><FontAwesomeIcon icon={faImages} /> Ver más fotos</Fragment>
        </button>
      );
    }

    return (
      <div className='Producto__container'>
        <div className='Producto row'>
          <div className='col-md-12 col-lg-6 Producto__imagen'>
            <img
              src={
                this.props.juego.imagen_url !== null
                  ? this.props.juego.imagen_url
                  : "https://s3.amazonaws.com/azuarainflables/images/no-disponible.png"
              }
              alt={this.props.juego.nombre}
            />
          </div>
          <div className='Producto__section-info col-md-12 col-lg-6'>
            <div className='row'>
              <div className='col'>
                <h3 className='Producto__nombre'>{this.props.juego.nombre}</h3>
                <p className='Producto__descripcion'>
                  {this.props.juego.descripcion_web}{" "}
                </p>
                <p className='Producto__medidas'>
                  Medidas <span>{this.props.juego.ancho}</span> x{" "}
                  <span>{this.props.juego.largo}</span> x{" "}
                  <span>{this.props.juego.alto}</span> de altura
                </p>
                <p className='Producto__precio_part'>
                  Precio Particular (max 7 hrs): $
                  {formatMoney(this.props.juego.precio_particular)}
                </p>
                <p className='Producto__precio_emp'>
                  Precio Empresa (max 5 hrs): $
                  {formatMoney(this.props.juego.precio_empresa)}
                </p>
                <p className='Producto__aviso'>{this.props.juego.aviso}</p>
              </div>
            </div>
            <div className='Producto__buttons row align-items-stretch'>
              <div className='col'>
                {ver_mas_fotos}
                {/* <button className='btn btn-default Producto__btn-reservar'>
                Reservar
              </button> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Producto;
