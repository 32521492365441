import React, { Component } from 'react';

import './styles/Loader.css';
import animacion from '../images/logo_loading.gif'

export default class Loader extends Component {
  render() {
    return (
      <div className="animate-div">
        <img src={animacion} width="50%" alt="loading" />
        <h2>Cargando...</h2>
      </div>
    );
  }
}
