import { Link } from 'react-router-dom';
import React, { Fragment } from "react";
import ImageGallery from "react-image-gallery";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons'

import api from "../api";
import ProductosList from "../components/ProductosList";
import PageLoading from "../components/PageLoading";
import PageError from "../components/PageError";

import "react-image-gallery/styles/css/image-gallery.css";
import "./styles/Juegos.css";


class Juegos extends React.Component {
  state = {
    gallery: false,
    loading: true,
    error: null,
    data: [],
    gallery_data: [],
    titulo: "Titulo",
  };

  componentDidMount() {
    this.setTitulo();
    this.fetchData();
    window.scrollTo(0,0);
  }

  fetchData = async () => {
    this.setState({ loading: true, error: null });

    try {
      const data = await api.list(this.props.match.params.juegosId);
      this.setState({ loading: false, data: data });
    } catch (error) {
      this.setState({
        loading: false,
        error: error,
      });
    }
  };

  showGallery = async (id) => {
    try {
      const data = await api.fotos(id);
      if (data.length>0){
        this.setState({ gallery: true, gallery_data: data });
      }
      else{
        this.setState({ gallery: false, gallery_data: [] });
      }
    } catch (error) {
      this.setState({ loading: false, error: error });
    }
  };

  handleGalleryClick = (e) => {
    this.showGallery(e.target.id);
  };

  handleClose = () => {
    this.setState({ gallery: false });
  };

  componentDidUpdate(oldProps, oldState) {
    // cambia la página y el juegosId, pero no cambia de pagina "juegos"
    if (this.props.match.params.juegosId !== oldProps.match.params.juegosId) {
      window.scrollTo(0,0);
      this.setTitulo();
      this.fetchData();
    }
  }

  setTitulo = () => {
    switch (this.props.match.params.juegosId) {
      case "1":
        this.setState({ titulo: "Juegos 1 - Para los más pequeños" });
        break;
      case "2":
        this.setState({ titulo: "Juegos 2 - Hasta 13 años" });
        break;
      case "3":
        this.setState({ titulo: "Juegos 3 - Para jóvenes y adultos" });
        break;
      case "4":
        this.setState({ titulo: "Juegos Acuáticos" });
        break;
      case "5":
        this.setState({ titulo: "Juegos Combinados" });
        break;
      default:
        this.setState({ titulo: "Productos" });
        break;
    }
  };

  render() {
    if (this.state.loading === true) {
      return <PageLoading />;
    }

    if (this.state.error) {
      return <PageError error={this.state.error} />;
    }
    var gallery = <div />;
    if (this.state.gallery) {
      gallery = (
        <Modal
          show={this.state.gallery}
          onHide={this.handleClose}
          size='xl'
          aria-labelledby='contained-modal-title-vcenter'
          centered>
          <Modal.Header closeButton />
          <Modal.Body>
            <ImageGallery items={this.state.gallery_data} autoPlay={true} slideInterval={5000} />
          </Modal.Body>
          <Modal.Footer>
            <Button variant='primary' onClick={this.handleClose}>
              Cerrar
            </Button>
          </Modal.Footer>
        </Modal>
      );
    }
    let anterior = "";
    if (this.props.match.params.juegosId === '2') anterior = <Link to='/juegos/1' className="btn btn-light"><Fragment><FontAwesomeIcon icon={faChevronLeft} /> Juegos 1</Fragment></Link>;
    else if (this.props.match.params.juegosId === '3') anterior = <Link to='/juegos/2' className="btn btn-light"><FontAwesomeIcon icon={faChevronLeft} /> Juegos 2</Link>;
    else if (this.props.match.params.juegosId === '4') anterior = <Link to='/juegos/3' className="btn btn-light"><FontAwesomeIcon icon={faChevronLeft} /> Juegos 3</Link>;
    else if (this.props.match.params.juegosId === '5') anterior = <Link to='/juegos/4' className="btn btn-light"><FontAwesomeIcon icon={faChevronLeft} /> Acuáticos</Link>;

    let siguiente = "";
    if (this.props.match.params.juegosId === '1') siguiente = <Link to='/juegos/2' className="btn btn-light">Juegos 2 <FontAwesomeIcon icon={faChevronRight} /></Link>;
    else if (this.props.match.params.juegosId === '2') siguiente = <Link to='/juegos/3' className="btn btn-light">Juegos 3 <FontAwesomeIcon icon={faChevronRight} /></Link>;
    else if (this.props.match.params.juegosId === '3') siguiente = <Link to='/juegos/4' className="btn btn-light">Acuáticos <FontAwesomeIcon icon={faChevronRight} /></Link>;
    else if (this.props.match.params.juegosId === '4') siguiente = <Link to='/juegos/5' className="btn btn-light">Combinados <FontAwesomeIcon icon={faChevronRight} /></Link>;

    return (
      <div className="Juegos">

        <div className='Juegos__container container'>
          <h1>{this.state.titulo}</h1>
          <ProductosList
            onGalleryClick={this.handleGalleryClick}
            juegos={this.state.data}
          />
          <div className="row">
            <div className="col-6">
              {anterior}
            </div>
            <div className="col-6 float-right">
              {siguiente}
            </div>
          </div>
        </div>
        {gallery}
      </div>
    );
  }
}

export default Juegos;
