import React from 'react';

import MyNavbar from './MyNavbar';
import Footer from './Footer';

function Layout(props) {
  // const children = props.children;

  return (
    <React.Fragment>
      <MyNavbar />
      {props.children}
      <Footer />
    </React.Fragment>
  );
}

export default Layout;
