const BASE_URL = 'http://azuarainflables.herokuapp.com';

const delay = ms => new Promise(resolve => setTimeout(resolve, ms));
const randomNumber = (min = 0, max = 1) =>
  Math.floor(Math.random() * (max - min + 1)) + min;
// eslint-disable-next-line no-unused-vars
const simulateNetworkLatency = (min = 30, max = 1500) =>
  delay(randomNumber(min, max));

// eslint-disable-next-line no-unused-vars
async function callApi(endpoint, options = {}) {
  // await simulateNetworkLatency();

  options.headers = {
    'Content-Type': 'application/json',
    'Accept': 'application/json',
  };

  const url = BASE_URL + endpoint;
  const response = await fetch(url, options);
  const data = await response.json();

  return data;
}

async function postApi(endpoint, data, options = {}) {
  options.headers = {
    'Content-Type': 'application/json',
    'Accept': 'application/json',
  };
  options.method = 'POST';

  options.body = JSON.stringify(data);

  const url = BASE_URL + endpoint;
  const response = await fetch(url, options);
  const resp = await response.json();

  return resp;
}

const api = {
    postContactForm(values){
      return postApi('/web/enviarcorreo/', values)
    },
    carrusel(){
      return callApi('/web/carrusel/');
    },
    list(id) {
        return callApi('/web/'+id+'/');      
    },
    fotos(id){
      return callApi('/web/producto/'+id+'/');
      /* return [
        {
          "original": "http://azuarainflables.com/juegos1/complete/cabanita.jpg",
          "thumbnail": "http://azuarainflables.com/juegos1/complete/cabanita.jpg"
        },
        {
          "original": "http://azuarainflables.com/juegos1/complete/gusanitos.jpg",
          "thumbnail": "http://azuarainflables.com/juegos1/complete/gusanitos.jpg"
        },
        {
          "original": "http://azuarainflables.com/juegos1/complete/corralito.jpg",
          "thumbnail": "http://azuarainflables.com/juegos1/complete/corralito.jpg"
        },
        {
          "original": "http://azuarainflables.com/juegos1/complete/minicastillo.jpg",
          "thumbnail": "http://azuarainflables.com/juegos1/complete/minicastillo.jpg"
        },
      
      ] */
    }
};

export default api;
