import React from 'react';
import './styles/Footer.css';

import footerbg from "../images/footerbg.png";
import facebook from "../images/facebook_logo.png"
import instagram from "../images/instagram_logo.png"
import whats1 from "../images/whats1.png"


export default function Footer(props){

    var wl1 = "https://wa.me/524424718080?text=Me%20interesa%20rentar%20un%20inflable";
    var wl2 = "https://wa.me/524424110343?text=Me%20interesa%20rentar%20un%20inflable";
    
    if (window.navigator.userAgent.indexOf("Linux") !== -1 && !(/Mobile|mini|Fennec|Android|iP(ad|od|hone)/.test(navigator.appVersion))) {
     wl1 = "https://web.whatsapp.com/send?l=es&phone=524424718080&text=Me%20interesa%20rentar%20un%20inflable";
     wl2 = "https://web.whatsapp.com/send?l=es&phone=524424110343&text=Me%20interesa%20rentar%20un%20inflable";
    }


    return (
        <div className="footer" style={{
            backgroundImage:`url(${footerbg})`,
            backgroundPosition:'top',
            backgroundSize:'cover',
            backgroundRepeat:'no-repeat'
          }}>
            <div className="container">
              <div className="row">
                  <div className="col-md-7">
                    <h1>Inflables Azuara</h1>
                    <h5>Comprometidos siempre con nuestros clientes para ofrecerles el mejor servicio posible.<br />
                    <br />Síguenos en Facebook o escríbenos por WhatsApp</h5>
                    <div className="row images">
                        <a className="col-lg-4 col-12" href="https://www.facebook.com/AzuaraInflables/" title="Ir a la página de Facebook">
                          <img src={facebook} alt="Facebook" height={100} /></a>
                        <a className="col-lg-4 col-6" href="https://www.instagram.com/inflablesazuara/?hl=es-la" title="Visítanos en Instagram">
                          <img src={instagram} alt="Instagram" height={100} /></a>
                        <a className="col-lg-4 col-6" href={wl2} title="Enviar Mensaje de WhatsApp">
                          <img src={whats1} alt="Whats" height={75} /></a>
                    </div>
                  </div>
                  <div className="col-md-5 datos">
                      <h5><a href="mailto:info@azuarainflables.com">info@azuarainflables.com</a></h5>
                      <h3>Teléfonos:<br /><a href="tel:4424110343">442-411-0343</a><br /><a href="tel:4424035798">442-403-5798</a></h3>
                      </div>
                </div>
            </div>
          </div>
    );
}

