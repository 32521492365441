import React, { Fragment } from "react";
import { BrowserRouter, Switch, Route } from "react-router-dom";
import "./styles/App.css";
import Juegos from "./Juegos";
import NotFound from "./NotFound";
import Home from "./Home";
import Layout from "../components/Layout";
import ContactForm from "../components/ContactForm";

/* import bg1 from "../images/bg7.jpg";
import bg2 from "../images/bg2.jpg";
import bg3 from "../images/bg3.jpg";
import bg4 from "../images/bg4.jpg";
import bg5 from "../images/bg5.jpg";
import bg6 from "../images/bg6.jpg";
import bg7 from "../images/bg7.jpg";
import bg8 from "../images/bg8.jpg";
import bg9 from "../images/bg9.jpg";

var bgImageArray = [bg1, bg2, bg3, bg4, bg5, bg6, bg7, bg8, bg9],
  secs = 30;
var fetch_count = 1;

function shuffle(array) {
  var currentIndex = array.length,
    temporaryValue,
    randomIndex;

  // While there remain elements to shuffle...
  while (0 !== currentIndex) {
    // Pick a remaining element...
    randomIndex = Math.floor(Math.random() * currentIndex);
    currentIndex -= 1;

    // And swap it with the current element.
    temporaryValue = array[currentIndex];
    array[currentIndex] = array[randomIndex];
    array[randomIndex] = temporaryValue;
  }

  return array;
}

function backgroundSequence() {
  window.clearTimeout();
  var k = 0;
  var x = document.getElementsByTagName("BODY")[0];
  for (var i = 0; i < bgImageArray.length; i++) {
    // eslint-disable-next-line no-loop-func
    setTimeout(function() {
      x.style.background =
        "url(" + bgImageArray[k] + ") no-repeat center center fixed";
      x.style.backgroundSize = "cover";
      if (k + 1 === bgImageArray.length) {
        setTimeout(function() {
          backgroundSequence();
        }, secs * 1000);
      } else {
        k++;
      }
    }, secs * 1000 * i);
  }
} */

class App extends React.Component {
  /* bgImageArray = shuffle(bgImageArray);
  componentDidMount() {
    bgImageArray.forEach(function(img) {
      setTimeout(() => {
        console.log("Cached", img);
        new Image().src = img;
      }, 200 * fetch_count);
      fetch_count++;
      // caches images, avoiding white flash between background replacements
    });
    setTimeout(() => {
      backgroundSequence();
    }, secs * 1000);
  }*/

  render() {
    return (
      <Fragment>
      <ul className="cb-slideshow">
            <li><span>Image 01</span></li>
            <li><span>Image 02</span></li>
            <li><span>Image 03</span></li>
            <li><span>Image 04</span></li>
            <li><span>Image 05</span></li>
            <li><span>Image 06</span></li>
            <li><span>Image 07</span></li>
            <li><span>Image 08</span></li>
        </ul>
      <BrowserRouter>
        <Layout>
          <Switch>
            <Route exact path='/' component={Home} />
            <Route exact path='/juegos/:juegosId' component={Juegos} />
            <Route exact path='/nosotros' component={Home} />
            <Route exact path='/contacto' component={ContactForm} />
            <Route component={NotFound} />
          </Switch>
        </Layout>
      </BrowserRouter>
      </Fragment>
    );
  }
}

export default App;
